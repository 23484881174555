<template>
    <b-card>
        <div class="d-flex align-items-center justify-content-between table-header">
            <h2 class="text-primary mb-1">
                {{ typeof $route.meta.pageTitle === 'function' ? $route.meta.pageTitle() : $route.meta.pageTitle }}
                {{ modelData.nama }}
            </h2>
            <div class="d-flex align-items-center">
                <b-button class="ml-1" variant="dark"
                    @click.prevent="$router.push(`/paket/detail/form/${$route.params.id}`)">
                    <feather-icon icon="ListIcon" />
                    <span class="d-none d-md-inline">Back To List</span>
                </b-button>
            </div>
        </div>

        <b-row>
            <b-col md="12">
                <validation-provider #default="{ errors }" name="kelompok" rules="required">
                    <b-form-group label="Kelompok" label-for="kelompok" :state="errors.length > 0 ? false : null">
                        <v-select id="kelompok" v-model="modelData.kelompok"
                            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :options="kelompokoptionfield"
                            label="text" placeholder="Pilih" />
                        <b-form-invalid-feedback :state="errors.length > 0 ? false : null">{{ errors[0]
                            }}</b-form-invalid-feedback>
                    </b-form-group>
                </validation-provider>
            </b-col>

            <b-col md="12">
                <validation-provider #default="{ errors }" name="makanan" rules="required">
                    <b-form-group label="Nama Makanan" label-for="makanan" :state="errors.length > 0 ? false : null">
                        <v-select id="makanan" v-model="modelData.makanan"
                            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :options="makananoptionfield"
                            label="text" placeholder="Pilih" />
                        <b-form-invalid-feedback :state="errors.length > 0 ? false : null">{{ errors[0]
                            }}</b-form-invalid-feedback>
                    </b-form-group>
                </validation-provider>
            </b-col>

            <b-col md="6">
                <b-form-group label="Punya Optional" label-for="kelompok">
                    <b-row>
                        <b-col md="6">
                            <b-input-group>
                                <b-input-group-prepend is-text>
                                    <b-form-radio v-model="modelData.radio" value="1" name="radio-input1" />
                                </b-input-group-prepend>
                                <b-form-input placeholder="Iya" readonly />
                            </b-input-group>
                        </b-col>

                        <b-col md="6">
                            <b-input-group>
                                <b-input-group-prepend is-text>
                                    <b-form-radio v-model="modelData.radio" value="0" name="radio-input1" />
                                </b-input-group-prepend>
                                <b-form-input placeholder="Tidak" readonly />
                            </b-input-group>
                        </b-col>
                    </b-row>
                </b-form-group>
            </b-col>

            <b-col md="6">
                <b-form-group label="Urutan" label-for="urutan">
                    <b-form-input id="urutan" v-model="modelData.urutan" type="tel" pattern="[0-9]*" max="2000" min="0"
                        placeholder="Masukkan urutan" @keypress="isLetter($event)" />
                </b-form-group>
            </b-col>
        </b-row>

        <b-button class="d-flex align-items-center mt-50" variant="success" @click.prevent="handleCreate">
            <feather-icon class="mr-50" icon="SaveIcon" />
            Save
        </b-button>
    </b-card>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { BRow, BCol, BFormGroup, BFormInput, BFormInvalidFeedback } from 'bootstrap-vue'
import { required } from '@validations'
import vSelect from 'vue-select'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import uiSchema from './schema/FormRequestUiSchema.json'
import schema from './schema/FormRequestSchema.json'

export default {
    components: {
        ValidationProvider,
        ValidationObserver,
        BRow,
        BCol,
        BFormGroup,
        BFormInput,
        vSelect,
        BFormInvalidFeedback,
        ToastificationContent,
    },
    data() {
        return {
            modelData: {
                kelompok: '',
                radio: '',
                makanan: '',
                urutan: null
            },
            idMenuPaket: 0,
            satuanoptionfield: ['Pax'],
            kelompokoptionfield: ['Main Dish', 'Side Dish', 'Soup', 'Vegetables', 'Dessert', 'Main Dish 2', 'Drink'],
            makananoptionfield: [],
            schema,
            uiSchema,
            loading: false
        }
    },
    mounted() {
        if (this.$route.params.id) {
            this.getDetailMenuPaket()
            this.getDetailData()
            this.getListDataMakanan()
        }
    },
    watch: {
        'modelData.kelompok': 'handleKelompokChange',
    },
    methods: {
        isLetter(e) {
            let char = String.fromCharCode(e.keyCode);
            if (/.*[0-9]/.test(char)) return true;
            else e.preventDefault();
        },

        handleKelompokChange() {
            this.getListDataMakanan();
        },

        handleCreate() {
            if (!this.validateForm()) {
                return
            }

            const data = {
                kodepaket: this.data.idMenuPaket,
                urutan: this.modelData.urutan,
                namamenu: this.modelData.kelompok,
                punyaoptional: this.modelData.radio,
                kodemakanan: this.modelData.makanan.value
            }

            this.$http.post('/api/menu/create', data).then(res => {
                if (!res.data.status) {
                    this.$toast({
                        component: ToastificationContent, position: 'top-right',
                        props: {
                            title: 'Notification',
                            icon: 'SlashIcon',
                            variant: 'danger',
                            text: res.data.message,
                        },
                    });
                    return
                }

                this.$toast({
                    component: ToastificationContent, position: 'top-right',
                    props: {
                        title: 'Notification',
                        icon: 'CheckIcon',
                        variant: 'success',
                        text: '👋Berhasil menyimpan data',
                    },
                });

                setTimeout(() => {
                    this.$router.push(`/paket/detail/form/${this.$route.params.id}`);
                }, 1000);
            }).catch(e => {
                this.$toast({
                    component: ToastificationContent, position: 'top-right',
                    props: {
                        title: 'Notification',
                        icon: 'SlashIcon',
                        variant: 'danger',
                        text: 'Gagal menyimpan data' + e,
                    },
                });
            })
        },

        getDetailData() {
            this.$http.get(`/api/paket/detail/${this.$route.params.id}`).then(res => {
                const response = res.data.data
                this.data = { idMenuPaket: response.kodepaket }
                this.modelData = {
                    nama: response.nama,
                    hargapaket: response.hargapaket,
                    satuan: response.satuan,
                    kodeerp: response.kodeerp,
                }
            }).catch(e => {
                this.$toast({
                    component: ToastificationContent, position: 'top-right',
                    props: {
                        title: 'Notification',
                        icon: 'SlashIcon',
                        variant: 'danger',
                        text: 'Gagal mendapatkan data' + e,
                    },
                });
            })
        },

        getDetailMenuPaket() {
            this.loading = true
            this.$http.get(`/api/menu/detail/${this.$route.params.id}`).then(res => {
                const response = res.data.data
                const totalItems = response.length;
                // if (!isNaN(totalItems)) {
                //     this.modelData = {
                //         urutan: parseInt(totalItems, 10) + 1
                //     };
                // } else {
                //     this.modelData = {
                //         urutan: null
                //     };
                // }
                this.loading = false
            }).catch(e => {
                this.$toast({
                    component: ToastificationContent, position: 'top-right',
                    props: {
                        title: 'Notification',
                        icon: 'SlashIcon',
                        variant: 'danger',
                        text: 'Gagal mendapatkan data' + e,
                    },
                });
            })
        },

        getListDataMakanan() {
            const params = {
                page: 1,
                perPage: 100000
            };

            this.$http.get('/api/makanan/list', { params: params }).then(res => {
                const response = res.data.data;
                const filteredMakanan = response.filter(item => item.kelompok === this.modelData.kelompok);

                this.makananoptionfield = [];

                filteredMakanan.forEach(element => {
                    const makananOption = {
                        text: element.namamakanan,
                        value: element.kodemakanan
                    };

                    this.makananoptionfield.push(makananOption);
                });
            }).catch(e => {
                this.$toast({
                    component: ToastificationContent, position: 'top-right',
                    props: {
                        title: 'Notification',
                        icon: 'SlashIcon',
                        variant: 'danger',
                        text: 'Gagal mendapatkan data' + e,
                    },
                });
            });
        },

        validateForm() {
            if (this.modelData.kelompok === '' || this.modelData.kelompok === null) {
                this.$toast({
                    component: ToastificationContent, position: 'top-right',
                    props: {
                        title: 'Notification',
                        icon: 'BellIcon',
                        variant: 'danger',
                        text: 'Silakan memilih kelompok makanan',
                    },
                });
                return false;
            }

            if (this.modelData.radio === '' || this.modelData.radio === null) {
                this.$toast({
                    component: ToastificationContent, position: 'top-right',
                    props: {
                        title: 'Notification',
                        icon: 'BellIcon',
                        variant: 'danger',
                        text: 'Silakan memilih pilihan optional',
                    },
                });
                return false;
            }

            if (this.modelData.makanan === '' || this.modelData.makanan === null) {
                this.$toast({
                    component: ToastificationContent, position: 'top-right',
                    props: {
                        title: 'Notification',
                        icon: 'BellIcon',
                        variant: 'danger',
                        text: 'Silakan memilih makanan',
                    },
                });
                return false;
            }

            if (this.modelData.urutan === '' || this.modelData.urutan === null) {
                this.$toast({
                    component: ToastificationContent, position: 'top-right',
                    props: {
                        title: 'Notification',
                        icon: 'BellIcon',
                        variant: 'danger',
                        text: 'Silakan masukkan urutan makanan',
                    },
                });
                return false;
            }

            return true
        }
    }
}
</script>